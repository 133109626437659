var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{directives:[{name:"can",rawName:"v-can",value:('users.create'),expression:"'users.create'"}]},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('b-form-group',[_c('div',{staticClass:"add-img-user text-center"},[_c('b-img',{staticClass:"height-180 width-180",attrs:{"fluid":"","center":"","thumbnail":"","src":_vm.preview,"alt":"Users"}}),_c('input',{attrs:{"type":"hidden"}}),_c('div',{staticClass:"p-image",attrs:{"align-h":""}},[_c('b-button',{staticClass:"upload-button iq-bg-primary position-relative",attrs:{"variant":"none","block":""}},[_c('input',{staticClass:"h-100 position-absolute btn-block",staticStyle:{"opacity":"0"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.previewImage}}),_vm._v(" CARGAR IMAGEN ")])],1)],1)])]},proxy:true}],null,true)})],1),_c('b-col',{attrs:{"lg":"9"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title mt-3"},[_vm._v("Nuevo Usuario")]),_c('h6',{staticClass:"card-subtitle text-muted mt-1"},[_vm._v(" Complete los campos para el registro de un nuevo usuario ")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"new-user-info"},[_c('b-row',[_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Nombres","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"Nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Ingrese sus nombres"},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Apellidos","label-for":"lastname"}},[_c('ValidationProvider',{attrs:{"name":"Apellidos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Ingrese sus apellidos"},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"RUT","label-for":"rut"}},[_c('ValidationProvider',{attrs:{"name":"RUT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['########-X', '#######-X']),expression:"['########-X', '#######-X']"}],class:errors.length > 0 || _vm.validateRut == false
                            ? ' is-invalid'
                            : '',attrs:{"value":_vm.validate(_vm.user.rut),"type":"text","placeholder":"RUT"},model:{value:(_vm.user.rut),callback:function ($$v) {_vm.$set(_vm.user, "rut", $$v)},expression:"user.rut"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.validateRut == false)?_c('span',[_vm._v("RUT invalido")]):_vm._e(),(
                            _vm.user.role === 'student'
                              ? _vm.user.representative.rut === _vm.user.rut
                                ? true
                                : false
                              : false
                          )?_c('span',[_vm._v("El RUT del estudiante no puede ser igual al del apoderado")]):_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Teléfono","label-for":"phone"}},[_c('ValidationProvider',{attrs:{"name":"phone","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('vue-tel-input',{attrs:{"inputOptions":{ placeholder: 'Telefono' }},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-sm-6",attrs:{"label-for":"comuna","label":"Comuna:"}},[_c('v-select',{attrs:{"placeholder":"Seleccionar...","label":"text","reduce":function (text) { return text.value; },"options":_vm.comunas},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados... ")]}}],null,true),model:{value:(_vm.user.comuna),callback:function ($$v) {_vm.$set(_vm.user, "comuna", $$v)},expression:"user.comuna"}})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Correo Electrónico","label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"Correo Electrónico","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Correo Electrónico"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label-for":"comuna","label":"Tipo de usuario"}},[_c('v-select',{attrs:{"placeholder":"Seleccione un tipo de usuario...","label":"text","reduce":function (text) { return text.value; },"options":_vm.optionsRoles},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados... ")]}}],null,true),model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1),(_vm.user.role === 'student')?[_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Nombres del apoderado","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"Nombres del apoderado","rules":_vm.user.role === 'student' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Ingrese sus nombres"},model:{value:(_vm.user.representative.firstname),callback:function ($$v) {_vm.$set(_vm.user.representative, "firstname", $$v)},expression:"user.representative.firstname"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Apellidos del apoderado","label-for":"lastname"}},[_c('ValidationProvider',{attrs:{"name":"Apellidos del apoderado","rules":_vm.user.role === 'student' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Ingrese sus apellidos"},model:{value:(_vm.user.representative.lastname),callback:function ($$v) {_vm.$set(_vm.user.representative, "lastname", $$v)},expression:"user.representative.lastname"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"RUT del apoderado","label-for":"rut"}},[_c('ValidationProvider',{attrs:{"name":"RUT del apoderado","rules":_vm.user.role === 'student' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['########-X', '#######-X']),expression:"['########-X', '#######-X']"}],class:errors.length > 0 || _vm.validateRut == false
                              ? ' is-invalid'
                              : '',attrs:{"value":_vm.validate(_vm.user.representative.rut),"type":"text","placeholder":"RUT"},model:{value:(_vm.user.representative.rut),callback:function ($$v) {_vm.$set(_vm.user.representative, "rut", $$v)},expression:"user.representative.rut"}}),(
                            _vm.user.role === 'student'
                              ? _vm.user.representative.rut === _vm.user.rut
                                ? true
                                : false
                              : false
                          )?_c('small',{staticClass:"text-danger"},[_vm._v("El RUT del apoderado no puede ser igual al del estudiante ")]):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[(_vm.validateRut == false)?_c('span',{staticClass:"text-danger"},[_vm._v("RUT invalido")]):_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Teléfono del apoderado","label-for":"phone"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono del apoderado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('vue-tel-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"inputOptions":{ placeholder: 'Telefono' }},model:{value:(_vm.user.representative.phone),callback:function ($$v) {_vm.$set(_vm.user.representative, "phone", $$v)},expression:"user.representative.phone"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Correo del apoderado","label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"Correo del apoderado","rules":_vm.user.role === 'student' ? 'email|required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Correo"},model:{value:(_vm.user.representative.email),callback:function ($$v) {_vm.$set(_vm.user.representative, "email", $$v)},expression:"user.representative.email"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]:_vm._e(),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":"Dirección","label-for":"addres"}},[_c('ValidationProvider',{attrs:{"name":"Dirección","rules":"min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-textarea',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"placeholder":"Ingrese una dirección de ubicación","rows":"1","max-rows":"6"},model:{value:(_vm.user.addres),callback:function ($$v) {_vm.$set(_vm.user, "addres", $$v)},expression:"user.addres"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Contraseña","label-for":"Contraseña"}},[_c('ValidationProvider',{attrs:{"name":"Contraseña","rules":"required|confirmed:password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"password","placeholder":"Contraseña"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Confirmar contraseña","label-for":"Confirmar contraseña"}},[_c('ValidationProvider',{attrs:{"vid":"password_confirmation","name":"Confirmar contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"password","placeholder":"Confirmar contraseña"},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],2),_c('hr'),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.user.role === 'student'
                      ? _vm.user.representative.rut === _vm.user.rut
                        ? true
                        : false
                      : false,"type":"submit"}},[_vm._v("Guardar")])],1)]},proxy:true}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }