<template>
  <b-container v-can="'users.create'">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(register)">
        <b-row>
          <b-col lg="3">
            <iq-card>
              <template v-slot:body>
                <b-form-group>
                  <div class="add-img-user text-center">
                    <b-img
                      class="height-180 width-180"
                      fluid
                      center
                      thumbnail
                      :src="preview"
                      alt="Users"
                    />
                    <input type="hidden" />
                    <div class="p-image" align-h>
                      <b-button
                        variant="none"
                        block
                        class="upload-button iq-bg-primary position-relative"
                      >
                        <input
                          type="file"
                          @change="previewImage"
                          class="h-100 position-absolute btn-block"
                          accept="image/*"
                          style="opacity: 0"
                        />
                        CARGAR IMAGEN
                      </b-button>
                    </div>
                  </div>
                </b-form-group>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="9">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title mt-3">Nuevo Usuario</h4>
                <h6 class="card-subtitle text-muted mt-1">
                  Complete los campos para el registro de un nuevo usuario
                </h6>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <!-- NOMBRE -->
                    <b-form-group
                      class="col-md-6"
                      label="Nombres"
                      label-for="name"
                    >
                      <ValidationProvider
                        name="Nombres"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.firstname"
                          type="text"
                          placeholder="Ingrese sus nombres"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <!-- APELLIDO -->
                    <b-form-group
                      class="col-md-6"
                      label="Apellidos"
                      label-for="lastname"
                    >
                      <ValidationProvider
                        name="Apellidos"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.lastname"
                          type="text"
                          placeholder="Ingrese sus apellidos"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <!-- RUT -->
                    <b-form-group class="col-md-6" label="RUT" label-for="rut">
                      <ValidationProvider
                        name="RUT"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.rut"
                          :value="validate(user.rut)"
                          type="text"
                          placeholder="RUT"
                          :class="
                            errors.length > 0 || validateRut == false
                              ? ' is-invalid'
                              : ''
                          "
                          v-mask="['########-X', '#######-X']"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span v-if="validateRut == false"
                            >RUT invalido</span
                          >
                          <span
                            v-if="
                              user.role === 'student'
                                ? user.representative.rut === user.rut
                                  ? true
                                  : false
                                : false
                            "
                            >El RUT del estudiante no puede ser igual al del
                            apoderado</span
                          >
                          <span v-else>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <!-- TELEFONO -->
                    <b-form-group
                      class="col-md-6"
                      label="Teléfono"
                      label-for="phone"
                    >
                      <ValidationProvider
                        name="phone"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <vue-tel-input
                          :inputOptions="{ placeholder: 'Telefono' }"
                          v-model="user.phone"
                        ></vue-tel-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <!-- COMUNA -->
                    <b-form-group
                      class="col-sm-6"
                      label-for="comuna"
                      label="Comuna:"
                    >
                      <v-select
                        v-model="user.comuna"
                        placeholder="Seleccionar..."
                        label="text"
                        :reduce="(text) => text.value"
                        :options="comunas"
                      >
                        <template #no-options="{}">
                          Sin Resultados...
                        </template>
                      </v-select>
                    </b-form-group>
                    <!-- CORREO -->
                    <b-form-group
                      class="col-md-6"
                      label="Correo Electrónico"
                      label-for="email"
                    >
                      <ValidationProvider
                        name="Correo Electrónico"
                        rules="email"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.email"
                          type="text"
                          placeholder="Correo Electrónico"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <!-- TIPO DE USUARIO -->
                    <b-form-group
                      class="col-md-6"
                      label-for="comuna"
                      label="Tipo de usuario"
                    >
                      <v-select
                        v-model="user.role"
                        placeholder="Seleccione un tipo de usuario..."
                        label="text"
                        :reduce="(text) => text.value"
                        :options="optionsRoles"
                      >
                        <template #no-options="{}">
                          Sin Resultados...
                        </template>
                      </v-select>
                    </b-form-group>
                    <!-- APODERADO -->
                    <template v-if="user.role === 'student'">
                      <!--NOMBRE DE APODERADO-->
                      <b-form-group
                        class="col-md-6"
                        label="Nombres del apoderado"
                        label-for="name"
                      >
                        <ValidationProvider
                          name="Nombres del apoderado"
                          :rules="user.role === 'student' ? 'required' : ''"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="user.representative.firstname"
                            type="text"
                            placeholder="Ingrese sus nombres"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        class="col-md-6"
                        label="Apellidos del apoderado"
                        label-for="lastname"
                      >
                        <ValidationProvider
                          name="Apellidos del apoderado"
                          :rules="user.role === 'student' ? 'required' : ''"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="user.representative.lastname"
                            type="text"
                            placeholder="Ingrese sus apellidos"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        class="col-md-6"
                        label="RUT del apoderado"
                        label-for="rut"
                      >
                        <ValidationProvider
                          name="RUT del apoderado"
                          :rules="user.role === 'student' ? 'required' : ''"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="user.representative.rut"
                            :value="validate(user.representative.rut)"
                            type="text"
                            placeholder="RUT"
                            :class="
                              errors.length > 0 || validateRut == false
                                ? ' is-invalid'
                                : ''
                            "
                            v-mask="['########-X', '#######-X']"
                          ></b-form-input>
                          <small
                            class="text-danger"
                            v-if="
                              user.role === 'student'
                                ? user.representative.rut === user.rut
                                  ? true
                                  : false
                                : false
                            "
                            >El RUT del apoderado no puede ser igual al del estudiante
                          </small>
                          <div class="invalid-feedback">
                          <span class="text-danger" v-if="validateRut == false"
                            >RUT invalido</span
                          >
                            <span v-else>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        class="col-md-6"
                        label="Teléfono del apoderado"
                        label-for="phone"
                      >
                        <ValidationProvider
                          name="Teléfono del apoderado"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <vue-tel-input
                            :inputOptions="{ placeholder: 'Telefono' }"
                            v-model="user.representative.phone"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></vue-tel-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <!-- CORREO DEL APODERADO -->
                      <b-form-group
                        class="col-md-6"
                        label="Correo del apoderado"
                        label-for="email"
                      >
                        <ValidationProvider
                          name="Correo del apoderado"
                          :rules="
                            user.role === 'student' ? 'email|required' : ''
                          "
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="user.representative.email"
                            type="text"
                            placeholder="Correo"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </template>
                    <!-- DIRECCION -->
                    <b-form-group
                      class="col-md-12"
                      label="Dirección"
                      label-for="addres"
                    >
                      <ValidationProvider
                        name="Dirección"
                        rules="min:10"
                        v-slot="{ errors }"
                      >
                        <b-form-textarea
                          placeholder="Ingrese una dirección de ubicación"
                          v-model="user.addres"
                          rows="1"
                          max-rows="6"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Contraseña"
                      label-for="Contraseña"
                    >
                      <ValidationProvider
                        name="Contraseña"
                        rules="required|confirmed:password_confirmation"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.password"
                          type="password"
                          placeholder="Contraseña"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Confirmar contraseña"
                      label-for="Confirmar contraseña"
                    >
                      <ValidationProvider
                        vid="password_confirmation"
                        name="Confirmar contraseña"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.password_confirmation"
                          type="password"
                          placeholder="Confirmar contraseña"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-row>
                  <hr />
                  <b-button
                    variant="primary"
                    :disabled="
                      user.role === 'student'
                        ? user.representative.rut === user.rut
                          ? true
                          : false
                        : false
                    "
                    type="submit"
                    >Guardar</b-button
                  >
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import verificador from "verificador-rut";
import { mapActions, mapGetters } from "vuex";
import { core } from "@/config/pluginInit";
export default {
  name: "AddUser",
  async mounted() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    this.$store.commit("run");
    core.index();
    await this.fetchRoles();
    const comunas = await this.$store.dispatch("tool/fetchComunas");
    this.comunas = comunas.map((item) => ({ value: item.id, text: item.name }));
    this.$store.commit("stop");
  },
  data() {
    return {
      comunas: [],
      user: {
        firstname: "",
        lastname: "",
        comuna: "",
        rut: "",
        phone: "",
        email: "",
        addres: "",
        avatar: "",
        password: "",
        password_confirmation: "",
        role: "",
        representative: {
          firstname: "",
          lastname: "",
          phone: "",
          rut: "",
          email: "",
        },
      },
      preview: require("../../../assets/images/default.png"),
      validateRut: true,
      items: [
        {
          html: 'Inicio',
          to: "/",
        },
        {
          html: "Listado De Usuarios",
          to: "/users/list",
        },
        {
          text: "Registro De Usuarios",
          active: true,
        },
      ],
    };
  },
  methods: {
    validate(rut) {
      if (verificador(rut)) {
        this.validateRut = true;
      } else {
        this.validateRut = false;
      }
    },
    async register() {
      try {
        let userForm = new FormData();
        for (let key in this.user) {
          if (this.user.role === "student" && key === "representative") {
            for (let re in this.user[key]) {
              userForm.append(`${key}[${re}]`, this.user[key][re]);
            }
          } else {
            if (key !== "representative") {
              userForm.append(key, this.user[key]);
            }
          }
        }
        this.$store.commit("run");
        const user = await this.$store.dispatch("user/addUser", userForm);
        this.$store.commit("stop");
        core.showSnackbar("success", "Usuario Agregado Correctamente");
        this.$router.replace("/users/list");
      } catch (error) {
        this.$store.commit("stop");
        let errors = error.response.data.errors;
        for (let key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    previewImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        this.user.avatar = input.files[0];
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    ...mapActions({
      fetchRoles: "tool/fetchRoles",
    }),
  },
  computed: {
    ...mapGetters({
      roles: "tool/getRoles",
    }),
    optionsRoles() {
      if (this.roles) {
        return this.roles.map(({ name: value, name: text }) => ({
          value,
          text:
            text === "teacher" ? "Profesor" : text === "student" ? "Estudiante" : text === "assistant" ? "Asistente" : "Administrativo",
        }));
      }
      return [];
    },
  },
};
</script>
